<template>
  <div class="container py-5">
    <div class="row" v-if="!loading">
      <div class="col-12">
        <button
          class="btn btn-outline-success btn-block btn-lg"
          @click="goCart()"
        >
          HACER NUEVO PEDIDO
        </button>
        <button
          class="btn btn-success btn-block btn-lg fixed-bottom"
          v-if="
            ticket.paymentStatus != 'ok' &&
            ticket.payment == 'Webpay' &&
            ticket.status != 'Cancelado'
          "
          @click="payTicket()"
          :disabled="loading"
        >
          PAGAR POR WEBPAY
        </button>
        <button
          class="btn btn-success btn-block btn-lg fixed-bottom"
          v-if="
            ticket.paymentStatus != 'ok' &&
            ticket.payment == 'Klap' &&
            ticket.status != 'Cancelado'
          "
          @click="payTicketKlap()"
          :disabled="loading"
        >
          PAGAR POR KLAP
        </button>
        <button
          class="btn btn-success btn-block btn-lg fixed-bottom"
          v-if="
            ticket.paymentStatus != 'ok' &&
            ticket.payment == 'MercadoPago' &&
            ticket.status != 'Cancelado'
          "
          @click="payTicketMercadoPago()"
          :disabled="loading"
        >
          PAGAR POR MERCADOPAGO
        </button>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col text-center mt-4 py-5">
        <div
          class="spinner-border"
          role="status"
          style="width: 3rem; height: 3rem"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <h4 class="mt-2">Cargando pedido....</h4>
        <button class="mt-4 btn btn-outline-danger" @click="load" v-if="tid && showReload">
          Recargar
        </button>
      </div>
    </div>
    <div class="row d-none">
      <div class="col-12">
        <b-button-group size="sm" class="w-100 mt-4">
          <b-button
            v-for="(btn, idx) in buttons"
            :key="idx"
            :pressed.sync="btn.state"
            variant="primary"
            :disabled="!btn.state"
          >
            {{ btn.caption }}
          </b-button>
        </b-button-group>
      </div>
    </div>
    <div class="row justify-content-center" v-if="ticket.status == 'Pendiente'">
      <div class="col-12 col-md-10 text-center mt-5 alert alert-info py-5">
        <div
          class="spinner-border"
          role="status"
          style="width: 3rem; height: 3rem"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <h4 class="mt-2">Tu pedido fue enviado al local</h4>
        <h6>Esperando confirmacion</h6>
        <small>La pagina se actualizará automaticamente</small>
        <div class="w-100">
          <small class="font-weight-bold"
            >Se ha enviado un correo a tu casilla, te mantendremos
            actualizado.</small
          >
          <small class="text-muted d-block"
            >Puede llegar a correo no deseado</small
          >
        </div>
        <div class="d-none">
          <b-button
            v-if="!ticket.suscription"
            size="sm"
            variant="outline-primary"
            @click="suscribe()"
            >Activar notificaciones</b-button
          >
          <b-button v-else variant="success" size="sm" disabled
            >Notificaciones activadas!</b-button
          >
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <div class="row justify-content-center mt-5" v-if="info">
          <div class="col">
            <div class="row" v-if="ticket.status == 'Cancelado'">
              <div class="col-12">
                <div class="alert alert-danger text-center">
                  <h1>:(</h1>
                  Tu pedido rechazado
                  <strong class="d-block">Motivo: {{ ticket.reason }}</strong>
                </div>
              </div>
            </div>
            <div class="row" v-if="ticket.status == 'Aceptado'">
              <div class="col-12">
                <div class="alert alert-success">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-bag-check"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                    />
                    <path
                      d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"
                    />
                  </svg>
                  Tu pedido fue aceptado por el local
                  <strong v-if="ticket.hour">
                    - Entrega aproximada:
                    {{ getAproxHour(ticket.hour) }}</strong
                  >
                </div>
              </div>
            </div>
            <div class="row" v-if="loading == false">
              <div
                class="col-12 py-3"
                v-if="calificationAvailable(ticket) && isQuery()"
              >
                <div class="row border my-2 py-3">
                  <div class="col-12">
                    <strong>CALIFICA TU PEDIDO</strong>
                    <b-form-rating
                      v-model="rating"
                      variant="warning"
                    ></b-form-rating>
                  </div>
                  <div class="col">
                    <small>COMENTARIO (OPCIONAL)</small>
                    <div class="input-group">
                      <textarea
                        class="form-control"
                        cols="10"
                        rows="3"
                        v-model="comment"
                      ></textarea>
                      <div class="input-group-ppend">
                        <button
                          class="btn btn-block btn-success h-100"
                          :disabled="loading || (rating == 0 && comment == '')"
                          @click="saveRating()"
                        >
                          ENVIAR
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-7">
                <h4 class="d-block text-uppercase font-weight-bold mb-1">
                  Pedido para {{ ticket.type }} #{{
                    ticket.number | formatNumber
                  }}
                </h4>
                <div v-if="info">
                  <h5>{{ info.custom_name || info.name }}</h5>
                  <h6>{{ info.address }}, {{ info.city }}</h6>
                </div>
                <div class="w-100 border-bottom"></div>
                <span class="d-block">
                  <strong>Cliente: </strong>{{ ticket.clientName }}
                </span>
                <span class="d-block">
                  <strong>Telefono: </strong>{{ ticket.phone }}
                </span>
                <span class="d-block" v-if="ticket.type == 'Domicilio'">
                  <strong>Direccion: </strong>{{ ticket.address }}
                </span>
                <span class="d-block">
                  <strong>Fecha: </strong
                  >{{ ticket.createdAt | moment("DD/MM/YY HH:mm") }}
                </span>
                <span class="d-block" v-if="ticket.date">
                  <strong>Programado: </strong
                  >{{
                    $moment()
                      .add(ticket.date, "day")
                      .hour(ticket.time)
                      .minutes(0)
                      .format("dddd DD [de] MMMM [-] HH:mm")
                  }}
                </span>
              </div>

              <div class="col-12 col-md-5">
                <div class="alert alert-warning mt-3 text-center">
                  <p>
                    Si tienes dudas o quieres cancelar tu pedido debes
                    comunicarte con el local
                  </p>
                  <a class="font-weight-bold" :href="`tel:${info.phone}`"
                    ><b-icon icon="phone" class="mr-2"></b-icon> Llamar
                    {{ info.phone }}</a
                  >
                </div>
                <div class="alert alert-success">
                  <button
                    class="btn btn-block"
                    @click="sendToWhatsApp()"
                    v-if="settings.whatsapp"
                  >
                    <b-icon icon="whatsapp" class="mr-2"></b-icon>
                    Enviar por Whatsapp
                  </button>
                </div>
              </div>

              <div
                class="ml-auto col-md-6 col-12 mt-3 d-none"
                v-if="
                  ticket.status == 'Aceptado' &&
                  ticket.payment == 'Transferencia' &&
                  false
                "
              >
                <div class="alert alert-info">
                  <h4>
                    Datos para transferencia por ${{
                      formatNumber(ticket.total)
                    }}
                  </h4>
                  <pre>{{ ticket.bankAccount }}</pre>
                </div>
              </div>

              <div
                class="col-md-12 col mt-3"
                v-if="ticket.status == 'Aceptado' && payment.details"
              >
                <div class="alert alert-info">
                  <h5 class="text-uppercase">
                    Detalles para {{ payment.name }}
                  </h5>
                  <div v-html="getContent(payment.details)"></div>
                </div>
              </div>

              <div class="col-md-12 col mt-3" v-if="ticket.notes">
                <div class="alert alert-info">
                  <small class="text-uppercase mb-2 d-block">
                    Notas del pedido
                  </small>
                  <div v-html="getContent(ticket.notes)"></div>
                </div>
              </div>

              <div class="col-12">
                <div
                  class="alert alert-success font-weight-bold mt-2"
                  v-if="ticket.paymentStatus == 'ok'"
                >
                  <div class="row align-items-center">
                    <div class="col text-center">
                      <b-icon
                        icon="check-circle"
                        style="width: 30px; height: 20px"
                      />
                      <span class="ml-3 d-inline-block">Pagado en linea</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-12 mt-4"
                v-if="ticket.flowOrders && ticket.flowOrders.length > 0"
              >
                <div class="row">
                  <div class="col-12">
                    <small class="text-muted">Pagos en linea</small>
                  </div>
                </div>
                <div
                  class="list-group-item"
                  v-for="event in ticket.flowOrders"
                  :key="event._id"
                >
                  <div class="row">
                    <div class="col-4 col-md-2">
                      <strong>{{ event.createdAt | moment("HH:mm") }}</strong>
                    </div>
                    <div class="col">
                      {{ event.status == "ok" ? "Pagado" : "No Pagado" }}
                    </div>
                    <div class="col">#{{ event.order }}</div>
                    <div class="col text-right">
                      <strong>${{ event.total | formatNumber }}</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4">
                <small class="text-center d-block">DETALLES</small>
                <div class="w-100 border-bottom"></div>
                <ul class="list-group">
                  <li
                    class="list-group-item"
                    v-for="p in ticket.products"
                    :key="p._id"
                  >
                    <div class="row">
                      <div class="col-8">
                        <span>{{ p.qty }} x </span>
                        <span class="font-weight-bold text-uppercase">{{
                          p.name
                        }}</span>
                        <small class="d-block text-muted">
                          VALOR ${{ p.value }}
                        </small>
                        <span>{{ p.details || "Sin Notas" }}</span>
                        <div class="w-100"></div>
                      </div>
                      <div class="col text-right">
                        ${{ formatNumber(p.total) }}
                      </div>
                    </div>
                    <div class="row pl-4" v-if="p.options">
                      <div class="col-12">
                        <small class="text-muted">Opciones</small>
                      </div>
                      <div class="col-8" v-for="op in p.options" :key="op._id">
                        <strong class="d-block text-uppercase"
                          >{{ op.label }}:
                        </strong>
                        <span v-if="op.values && op.values.length > 0">{{
                          op.values.map((o) => o.name).join(", ")
                        }}</span>
                        <span v-else>{{ op.selected }}</span>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item list-group-item-dark">
                    <div class="row font-weight-bold">
                      <div class="ml-auto col-6 text-right">Medio de pago</div>
                      <div class="col-4 text-right">{{ ticket.payment }}</div>
                    </div>
                  </li>
                  <li class="list-group-item list-group-item-dark">
                    <div class="row font-weight-bold">
                      <div class="ml-auto col-6 text-right">SUB-TOTAL</div>
                      <div class="col-4 text-right">
                        ${{ formatNumber(sub_total) }}
                      </div>
                    </div>
                  </li>
                  <li
                    class="list-group-item list-group-item-dark"
                    v-if="ticket.discount"
                  >
                    <div class="row font-weight-bold">
                      <div class="ml-auto col-6 text-right">DESCUENTO</div>
                      <div class="col-4 text-right">
                        -${{ formatNumber(ticket.discount) }}
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item list-group-item-dark d-none">
                    <div class="row font-weight-bold">
                      <div class="ml-auto col-6 text-right">Estado de pago</div>
                      <div class="col-4 text-right">
                        {{ ticket.payment_status }}
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item list-group-item-dark d-none">
                    <div class="row font-weight-bold">
                      <div class="ml-auto col-6 text-right">Envio</div>
                      <div class="col-4 text-right">
                        ${{ formatNumber(ticket.deliveryValue) }}
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item list-group-item-dark">
                    <div class="row font-weight-bold">
                      <div class="ml-auto col-4 text-right">TOTAL</div>
                      <div class="col-4 text-right">
                        ${{ formatNumber(ticket.total) }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="modal"
      title="Cargando..."
      no-close-on-backdrop
      hide-header
      hide-footer
    >
      <div class="row py-5">
        <div class="col text-center">
          <b-spinner
            variant="success"
            size="xl"
            type="grow"
            label="Spinning"
          ></b-spinner>
          <div class="w-100 py-2"></div>
          Cargando Pago...
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showReload: false,
      comment: "",
      rating: 0,
      ticket: {
        business: {},
      },
      tid: 0,
      loading: true,
      buttons: [
        { caption: "1. Pedido Enviado", state: false },
        { caption: "2. Esperando Respuesta del local", state: true },
        { caption: "3. Confirmacion", state: false },
        { caption: "4. Entrega", state: false },
      ],
    };
  },
  mounted() {
    window.scroll(0, 0);
    this.tid = this.$route.params.id;
    this.load();
    //this.checkPreference()
    this.$gtag.event("view_ticket", {
      event_category: "ticket",
      event_label: "User view ticket",
      value: 1,
    });
  },
  computed: {
    info() {
      return this.$store.state.info;
    },
    sub_total() {
      return this.ticket.products.reduce((t, a) => t + a.total, 0);
    },
    settings() {
      return this.info && this.info.settings ? this.info.settings : {};
    },
    payment() {
      return (
        this.settings.payments.find((p) => {
          return p.name == this.ticket.payment;
        }) || {}
      );
    },
  },
  methods: {
    checkPreference() {
      const { preference_id } = this.$route.query;

      if (!preference_id) return console.log("no preference");

      console.log("preference_id", preference_id);

      this.$http
        .get(`mercadopago/${preference_id}`)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendToWhatsApp() {
      let msg = `Hola, mi nombre es *${this.ticket.clientName}*\n`;
      msg += `Pedido para *${this.ticket.type}*.\n`;
      msg += `🎫 Numero de pedido: #${this.ticket.number}\n\n`;
      msg += `Mi pedido es:\n`;
      msg += "-".repeat(30) + "\n";

      this.ticket.products.forEach((p) => {
        msg += `✅ *${p.qty} x ${p.name}* | $${p.total}\n`;
        // if (p.options) {
        //   p.options.forEach((op) => {
        //     msg += `  - ${op.label}: ${op.selected}\n`;
        //   });
        // }
        if (p.details) msg += `  - Notas: ${p.details}\n`;
      });
      msg += `\n💲 *Total: $${this.ticket.total}*\n`;
      msg += `📱 Telefono: +56${this.ticket.phone}\n`;
      if (this.ticket.type != "Retiro")
        msg += `🗺️ Direccion: ${this.ticket.address}\n`;
      let date = this.$moment(this.ticket.createdAt).format("DD/MM/YY");
      msg += `📅 Fecha: ${date}\n`;
      if (this.ticket.hour) msg += `⌚ Hora: ${this.ticket.hour}\n`;
      msg += `Medio de pago: ${this.ticket.payment}\n`;
      msg += `Notas: ${this.ticket.notes || "Sin notas"}\n`;
      msg += `\n\n`;

      let phone = this.settings.whatsapp;
      window.open(
        `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
          msg
        )}`,
        "_blank"
      );
    },
    isQuery() {
      let token = this.ticket.createdAt.slice(-4);
      if (token == this.$route.query.s) return true;
      return false;
    },
    saveRating() {
      this.loading = true;
      let tid = this.ticket._id;
      this.$http
        .post(`account/ticket/${tid}/rating`, {
          note: this.rating,
          comment: this.comment,
        })
        .then(() => {
          this.load();
          //this.$refs["view-ticket"].hide();
        })
        .catch(() => {
          this.$swal({
            title: "Error al guardar, intente nuevamente",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    payTicket() {
      this.$refs["modal"].show();
      this.$http
        .post(`${this.ticket._id}/flow`)
        .then((res) => {
          let data = res.data.data;
          console.log("ta listo");
          window.location.href = `${data.url}?token=${data.token}`;
        })
        .catch((err) => {
          alert("Error al cargar el pago");
          this.$refs["modal"].hide();
          console.error(err);
          // setTimeout(() => {
          //   this.loading = false;
          // }, 10000);
        });
    },
    payTicketKlap() {
      this.$refs["modal"].show();
      this.$http
        .post(`${this.ticket._id}/klap`)
        .then((res) => {
          let data = res.data;
          console.log("ta listo", data);
          window.location.href = `${data.redirect_url}`;
        })
        .catch((err) => {
          alert("Error al cargar el pago");
          this.$refs["modal"].hide();
          console.error(err);
          // setTimeout(() => {
          //   this.loading = false;
          // }, 10000);
        });
    },
    payTicketMercadoPago() {
      this.$refs["modal"].show();
      const url = window.location.href.split("?")[0];
      this.$http
        .post(`${this.ticket._id}/mercadopago`, {
          url,
        })
        .then((res) => {
          let data = res.data;
          console.log("ta listo");
          window.location.href = data.init_point;
        })
        .catch((err) => {
          //alert("Error al cargar el pago");
          this.$swal({
            title: "Error al cargar el pago",
          });
          this.$refs["modal"].hide();
          console.error(err);
          // setTimeout(() => {
          //   this.loading = false;
          // }, 10000);
        });
      console.log("mercado pago");
    },
    cutEmail() {
      let email = this.ticket.email;
      let a = email.indexOf("@");
      let r = email.slice(a, a - 3);
      return email.replace(r, "****");
    },
    getContent(content) {
      return content
        .replaceAll("\n", "<br>")
        .replaceAll(
          '<a href="',
          '<a class="font-weight-bold" target="_blank" href="'
        );
    },
    getAproxHour() {
      let time = parseInt(this.settings.deliveryTime);
      let hour = this.$moment(this.ticket.hour, "HH:mm");
      if (!time || this.ticket.type == "Retiro")
        return `${hour.format("HH:mm")}`;
      return `${hour.format("HH:mm")} - ${hour
        .add(time, "minutes")
        .format("HH:mm")}`;
    },
    goCart() {
      this.$cookies.remove("ticketId");
      if (this.settings.style == "delivery")
        this.$router.push(this.getLink("menu"));
      else this.$router.push(this.getLink("productos"));
    },
    // suscribe() {
    //   if (!("Notification" in window)) {
    //     return alert(
    //       "Este navegador no es compatible con las notificaciones de escritorio"
    //     );
    //   }
    //   this.nstatus = Notification.permission;
    //   Notification.requestPermission(() => {
    //     this.nstatus = Notification.permission;
    //     navigator.serviceWorker
    //       .register("/worker.js", {
    //         scope: "/",
    //       })
    //       .then((register) => {
    //         // console.log(register);
    //         return register.pushManager.subscribe({
    //           userVisibleOnly: true,
    //           applicationServerKey: this.$store.state.PVK,
    //         });
    //       })
    //       .then((suscription) => {
    //         this.nstatus = Notification.permission;
    //         return this.$http.post(`ticket/${this.tid}/suscribe`, {
    //           s: JSON.stringify(suscription),
    //         });
    //       })
    //       .then(() => {
    //         this.load();
    //       })
    //       .catch(() => {
    //         alert("Error intente nuevamente");
    //       });
    //   }).catch(() => {
    //     alert("Error intente nuevamente");
    //   });
    // },
    load() {
      this.showReload = false;
      
      this.$http
        .get("ticket/" + this.tid)
        .then((res) => {
          this.ticket = res.data.data;

          if (
            this.ticket.payment == "MercadoPago" &&
            this.ticket.paymentStatus != "ok"
          ) {
            this.payTicketMercadoPago();
          }

          if (
            this.ticket.payment == "Klap" &&
            this.ticket.paymentStatus != "ok"
          ) {
            //this.payTicketKlap();
          }

          this.$http.get(res.data.data.b).then((res2) => {
            this.$store.commit("setInfo", res2.data.data);
          });
          if (this.ticket.status == "Pendiente") setTimeout(this.load, 60000);

          this.loading = false;
        })
        .catch(() => {
          // alert("ERROR");
          this.showReload = true;
        });
    },
  },
  watch: {
    info() {
      document.title = `Ticket - ${this.info.custom_name || this.info.name}`;
    },
  },
};
</script>